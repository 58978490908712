.svgContainer {
  height: 5rem;
  width: 5rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgContainer svg {
  height: 100%;
  width: 100%;
}
