.container {
  width: 100%;
  padding: 10%;

  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: rgb(180, 180, 180);
}

.title {
  font-weight: 600;
  font-size: 2rem;
  color: var(--link);
  font-family: var(--clickable);
}

.projectList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.project {
  background-color: var(--light);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.img {
  width: min(80%, 100rem);
  border-radius: 1rem;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link:hover {
  box-shadow: none;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tab {
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: var(--link);
  color: black;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.footerContent {
  color: var(--link);
  font-family: var(--clickable);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
}

.footerContent:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: var(--link);
  border-radius: 1rem;
}

.disclaimer {
  color: white;
  font-weight: 600;
}

@media screen and (min-width: 50em) {
  .container {
    padding-inline: 30vw;
  }
}
