.container {
  height: calc(100vh);
  padding-inline: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5%;

  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: rgb(180, 180, 180);
}

.headline {
  color: var(--link);
  font-size: 1.75rem;
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.avatarContainer {
  align-self: center;
  position: relative;
  display: flex;
  width: 50%;
}

.avatarContainer:hover .avatarShadow {
  background-color: var(--link);
}

.avatarShadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5%;
  left: -5%;
  background-color: var(--light);
  z-index: -1;
  border-radius: 1rem;
  transition: ease 0.5s;
}

.avatar {
  width: 100%;
  border-radius: 1rem;
}

@media screen and (min-width: 50em) {
  .container {
    padding-inline: 30vw;
  }

  .avatarContainer {
    align-self: center;
    position: relative;
    display: flex;
    width: 30%;
  }
}
