:root {
  --link: #6fd2ff;
  --light: #6fd1ff34;
  --text: #e5e5e5;
  --background: black;
  --background-light: rgba(0, 0, 0, 0.85);
  --clickable: "Roboto Mono", monospace;
}

html,
body {
  height: 100%;
  font-size: 62.5%;
  color: var(--text);
  scroll-behavior: smooth;
}

html.scrollbar-hidden {
  padding-right: 13px;
  background: var(--background);
}

*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: var(--background);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 6px;
  border: 3px solid var(--background);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .content > *:not(.menu),
body .hero > * {
  transition: filter ease 1s;
  filter: blur(0px) brightness(1);
}

body.blur .content > *:not(.menu),
body.blur .hero > * {
  transition: filter ease 0.5s;
  filter: blur(5px) brightness(0.3);
}

body.blur {
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  scrollbar-color: var(--light) var(--background);
  -webkit-tap-highlight-color: transparent;
}

h1 {
  color: var(--link);
  position: relative;
  padding-block: 1rem;
}

h1::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--link);
  border-radius: 10rem;
}

a {
  text-decoration: none;
}

button,
a {
  outline: none;
  border: none;
  background-color: unset;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
}

a:hover,
button:hover {
  box-shadow: 0 0 10px 5px var(--link);
}

@media screen and (max-width: 30rem) {
  a:hover,
  button:hover {
    box-shadow: none;
  }

  a:active,
  button:active {
    box-shadow: 0 0 10px 5px var(--link);
  }
}

button:disabled:hover {
  box-shadow: none;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
