.container {
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: ease 0.3s;
}

.open {
  z-index: 1;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  width: 100%;
  transition: ease 0.3s;
  color: var(--link);
  font-size: 3rem;
  font-family: var(--clickable);
}

.item:nth-of-type(odd) {
  transform: translateX(-100vw);
}

.item:nth-of-type(even) {
  transform: translateX(100vw);
}

.open .item {
  transform: translateX(0);
}

.link {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.innerLink {
  padding: 1rem;
  font-size: 2rem;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 25%;
}

.icons {
  display: flex;
  gap: 1rem;
}

.contact .link {
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.icon {
  font-size: 5rem !important;
}
