.container {
  height: calc(100vh);
  font-size: clamp(1rem, 1vw, 1.5rem);
  padding-inline: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4em;
}

.name {
  font-size: 5em;
  font-weight: 200;
  letter-spacing: 1px;
}

.sub {
  font-size: 3em;
  font-weight: 200;
  color: rgb(180, 180, 180);
  font-family: var(--clickable);
  color: var(--link);

  animation: typing 2s steps(18), blink 0.5s step-end alternate infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 1px solid;
  font-family: monospace;
  width: 18ch;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.blurb {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: rgb(180, 180, 180);
}

.link {
  color: var(--link);
  padding: 0;
}

.blurb .link:hover {
  box-shadow: none;
  text-decoration-line: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 2px;
}

.contact {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: rgb(180, 180, 180);
}

@media screen and (min-width: 50em) {
  .container {
    padding-inline: 30vw;
  }
}
