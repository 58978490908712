.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  gap: 1rem;
  padding: 1rem;
}

.bar {
  width: 3rem;
  height: 0.2rem;
  background-color: var(--link);
  border-radius: 100rem;
  transition: ease 0.5s;
}

.bar:nth-of-type(2) {
  width: 4rem;
}

.active .bar:nth-of-type(1) {
  transform: translateY(1.1rem) rotate(225deg);
  width: 4rem;
}

.active .bar:nth-of-type(2) {
  width: 0px;
  height: 0px;
}

.active .bar:nth-of-type(3) {
  transform: translateY(-1.1rem) rotate(-225deg);
  width: 4rem;
}
