.technologies {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

.columnHeader {
  color: var(--link);
  font-size: 1.5rem;
}
